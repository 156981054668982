<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="Number(query.type) === 0 && !isEmpty">
          <div class="list_page">
            <div class="bill_img_box">
              <!--지워주세요-->
              <span style="font-size: 20px;" v-html="contents"></span>
            </div>
          </div>
        </div>
        <div class="page_con" v-if="Number(query.type) !== 0 && !isEmpty">
          <div
            class="list_page"
            v-for="(item, index) in filePaths"
            :key="index"
          >
            <div class="bill_img_box">
              <img
                :src="$ConstCode.getImagePath(item.imagePath, 'origin/')"
                alt="img"
                class="bill_img"
                @click="fnPopUp(item.imagePath)"
              />
            </div>
          </div>
        </div>
        <empty-index v-if="isEmpty" :content="'내역이 없습니다.'" />
      </div>
    </div>
  </main>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { reactive, getCurrentInstance, toRefs, computed } from 'vue'
import EmptyIndex from '@/components/views/empty/full'

const layoutState = () => {
  const { getters, dispatch } = useStore()
  const routeLayout = computed(() => {
    return getters['layout/getRouteLayout']
  })
  const fnUpdateRouteLayout = ({ titleName = '' }) => {
    dispatch('layout/updateRouteLayout', {
      titleName: titleName
    })
  }
  return { routeLayout, fnUpdateRouteLayout }
}

export default {
  name: 'menu-building-status-view',
  components: { EmptyIndex },
  description: '빌라 현황 상세페이지',
  setup() {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const { dispatch } = useStore()
    const query = route.query

    const state = reactive({
      contents: '',
      filePaths: [],
      isEmpty: false
    })

    const fetchData = async () => {
      if (Number(query.type) === 0) {
        const res = await proxy.$ManageSvc.fetchUserCompany()
        if (res.code === 1) {
          state.contents = res.entity.contents
          if (proxy.$Util.isEmpty(res.entity.contents)) {
            state.isEmpty = true
          }
        }
      } else {
        const res = await proxy.$ManageSvc.fetchUserManage(query)
        if (res.code === 1) {
          state.filePaths = res.entity.filePaths
          if (proxy.$Util.isEmpty(res.entity.filePaths)) {
            state.isEmpty = true
          }
        }
      }
    }

    const { fnUpdateRouteLayout } = layoutState()
    let titleName = ''
    if (Number(query.type) === proxy.$ConstCode.BUILDING_STATUS.COMPANY.value) {
      titleName = proxy.$ConstCode.BUILDING_STATUS.COMPANY.name
    } else if (
      Number(query.type) === proxy.$ConstCode.BUILDING_STATUS.PARKING.value
    ) {
      titleName = proxy.$ConstCode.BUILDING_STATUS.PARKING.name
    } else if (
      Number(query.type) === proxy.$ConstCode.BUILDING_STATUS.NOTICE.value
    ) {
      titleName = proxy.$ConstCode.BUILDING_STATUS.NOTICE.name
    } else if (
      Number(query.type) === proxy.$ConstCode.BUILDING_STATUS.MANAGE.value
    ) {
      titleName = proxy.$ConstCode.BUILDING_STATUS.MANAGE.name
    }
    fnUpdateRouteLayout({ titleName })
    const fnPopUp = async path => {
      const payload = {}
      payload.component = proxy.$modalAlertNames.IMAGE
      payload.layout = {
        imagePath: path
      }
      payload.callBack = proxy => {
        proxy.$emit('close')
      }
      await dispatch('layout/pushModalAlertComponent', payload)
    }

    fetchData()
    return { ...toRefs(state), fetchData, query, ...layoutState(), fnPopUp }
  }
}
</script>

<style scoped></style>
